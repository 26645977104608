.main {
    width: 80%;
    margin: auto;
    margin-block: 50px;
    height: 80vh;
    /* border: 1px solid red; */
    padding: 10px;
}

.comment_main {
    display: flex;
    gap: 10px;
    height: 50px;
    width: 60%;
    margin: auto;
    justify-content: start;
    align-items: flex-start;
    /* border: 1px solid red; */
}

.comment_main>img {
    width: 30px;
    padding: 5px;
    border: 2px solid gray;
    border-radius: 5px;
}

.comment_main>textarea {
    width: 100%;
    height: 50px;
    padding: 5px 15px;
    border: 2px solid gray;
    border-radius: 5px;
    font-size: 18px;
    /* resize: none; */
}

.comment_main>button {
    padding: 10px 15px;
    border: 2px solid gray;
    border-radius: 5px;
    font-size: 18px;
}