.main {
    width: 100%;
    height: 30px;
    padding: 15px 0;
    gap: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    border-bottom: 1px solid red;
}

.main>Link {
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
}